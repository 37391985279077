<template>
  <b-modal hide-header hide-footer id="ProductCardModal" okVariant="success">

    <div class="d-flex product-model-image-details-wrapper flex-row ">
      <div class="product-model-details-wrapper">
        <div>
          <div class="product-name-ratings-wrapper align-items-start flex-column flex-lg-row">
            <span class="product-name">{{ product.name }}</span>
            <Rating :stars="product.rating_summary" :noOfRatings="product.review_count"
              v-if="product.review_count > 0" />
          </div>
          <p class="product-number"> <strong> Produktnummer: </strong> {{ product.sku }} </p>
          <p v-if="product.inhalt" class="product-inhalt"> <strong> Inhalt: </strong> {{ product.inhalt }} </p>
          <p class="my-price">
            {{ priceRange.maximum_price.final_price.value.toFixed(2).replace(".", ",") }}&euro;
          </p>

          <div class="btn-wrapper align-md-items-start my-md-5  flex-column flex-md-row">
            <b-link to="checkout" class="btn my-1 mb-sm-0 mb-10 text-white btn-warning">{{ $t('proceed_to_checkout')
              }}</b-link>
            <b-link @click="close()" class="my-1 mb-sm-0 mb-10 btn btn-outline-dark">
              {{ $t('continue_shopping') }}</b-link>
          </div>
        </div>
      </div>
      <div class="product-img-wrapper">
        <img :src="product.image.large" v-if="product.image.large" />
      </div>
    </div>

    <section class="model-product-section" v-if="products && products.length > 0">
      <div class="container">
        <span class="section-title">other</span>
        <ClientOnly>
          <MoreProducts class="pt-5" :products="products" noProuct="4" type="modal" />
        </ClientOnly>
      </div>
    </section>

    <div class="text-right pt-10">
      <b-link @click="close()" class="close-arrow"><font-awesome-icon :icon="downRightArrow" size="2x" /></b-link>
    </div>
  </b-modal>
</template>

<script>
import ClientOnly from "vue-client-only";
import MoreProducts from "@/esf_kerkrade_vitanatura/core/components/MoreProducts.vue";
import Rating from "@/esf_kerkrade_vitanatura/core/components/Rating";
import { faArrowDownRight } from '@fortawesome/pro-light-svg-icons';

export default {
  name: "ProductCardModal",
  data() {
    return {
      downRightArrow: faArrowDownRight
    }
  },
  props: {
    product: Object,
    priceRange: Object,
    quantity: Number,
    products: Array,
  },
  components: {
    ClientOnly,
    MoreProducts,
    Rating
  },
  methods: {
    close() {
      this.$bvModal.hide("ProductCardModal")
    },
  },
};
</script>